exports.components = {
  "component---src-atomic-pages-page-index-jsx": () => import("./../../../src/atomic/pages/page/index.jsx" /* webpackChunkName: "component---src-atomic-pages-page-index-jsx" */),
  "component---src-atomic-pages-vacancy-index-jsx": () => import("./../../../src/atomic/pages/vacancy/index.jsx" /* webpackChunkName: "component---src-atomic-pages-vacancy-index-jsx" */),
  "component---src-templates-certificate-index-jsx": () => import("./../../../src/templates/certificate/index.jsx" /* webpackChunkName: "component---src-templates-certificate-index-jsx" */),
  "component---src-templates-geo-index-jsx": () => import("./../../../src/templates/geo/index.jsx" /* webpackChunkName: "component---src-templates-geo-index-jsx" */),
  "component---src-templates-partner-index-jsx": () => import("./../../../src/templates/partner/index.jsx" /* webpackChunkName: "component---src-templates-partner-index-jsx" */),
  "component---src-templates-press-center-index-jsx": () => import("./../../../src/templates/press-center/index.jsx" /* webpackChunkName: "component---src-templates-press-center-index-jsx" */),
  "component---src-templates-press-center-post-index-jsx": () => import("./../../../src/templates/press-center/post/index.jsx" /* webpackChunkName: "component---src-templates-press-center-post-index-jsx" */),
  "component---src-templates-seo-index-jsx": () => import("./../../../src/templates/seo/index.jsx" /* webpackChunkName: "component---src-templates-seo-index-jsx" */),
  "component---src-templates-welcome-index-jsx": () => import("./../../../src/templates/welcome/index.jsx" /* webpackChunkName: "component---src-templates-welcome-index-jsx" */)
}

